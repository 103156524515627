.ant-page-header-footer {
    margin-top: 0;
}

.ant-page-header {
    padding-top: 0;
    padding-bottom: 0;
}

h3.ant-typography {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5715;
    white-space: nowrap;
    margin: 0.5em 0;
}

h4.ant-typography {
    font-weight: bold;
    font-size: 14px;
    line-height: 1.4715;
    white-space: nowrap;
    margin: 0.5em 0;
}

.ant-form-item {
    /* default 24px */
    margin-bottom: 10px;
}

.ant-input-number{
    width: inherit;
}


/* Makes tables smaller */
.ant-table-thead > tr > th, 
.ant-table-tbody > tr > td, 
.ant-table tfoot > tr > th, 
.ant-table tfoot > tr > td {
    padding: 6px;
}
.ant-table.ant-table-middle .ant-table-thead > tr > th, 
.ant-table.ant-table-middle .ant-table-tbody > tr > td, 
.ant-table.ant-table-middle tfoot > tr > th, 
.ant-table.ant-table-middle tfoot > tr > td {
    padding: 4px;
}
.ant-table.ant-table-small .ant-table-thead > tr > th, 
.ant-table.ant-table-small .ant-table-tbody > tr > td, 
.ant-table.ant-table-small tfoot > tr > th, 
.ant-table.ant-table-small tfoot > tr > td {
    padding: 2px;
}
tr.ant-table-expanded-row > td {
    padding: 0 !important;
    padding-left: 8px !important;
}
.ant-table-expand-icon-col {
    width: 24px;
}
/* detail table - reduce margin */
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: 0 0 0 22px;
    /*margin: -16px -16px -16px 33px; original */
}


/* make timeline items closer together */
.ant-timeline-item {
    padding-bottom: 4px; /* default 20px */
}
.ant-timeline-item-last > .ant-timeline-item-content {
    min-height: auto; /* was 48px */
}

/* tamniji text u disabled inputima da se lakse cita */
input[type="text"]:disabled,
input[type="search"]:disabled,
input:not([type]):disabled,
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
textarea.ant-input.ant-input-disabled,
.ant-checkbox-disabled + span,
.ant-radio-disabled + span {
    color:#333 !important;
}


/* default: margin-top: 20px, bottom: 0 */
.ant-descriptions-header {
    margin: 10px;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
    background-color: #f2f2f2;
}

.ant-pagination{
    text-align: right;
}
.ant-pagination-total-text {
    padding-top: 1px;
}

@primary-color: #3a76cc;@info-color: @primary-color;@success-color: #2ca841;@processing-color: #3a76cc;@error-color: #d62d2d;@highlight-color: #d62d2d;@warning-color: #e8782d;@normal-color: #fcf5f5;@white: #fff;@black: #000;@menu-dark-submenu-bg: #111;@layout-header-background: #19192c;@layout-trigger-background: #19192c;@background-color-light: hsv(0, 0, 96%);@background-color-base: hsv(0, 0, 94%);@border-color-base: hsv(0, 0, 85%);