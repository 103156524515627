html, body, #root {
    margin: 0;
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%;
}

#root {
    height:100%;
    width:100%;
}

.box-flex-row {
    display: flex;
}

.box-flex-col {
    display: flex;
    flex-direction: column;
}

.box-overflow-y {
    overflow-y: auto;
}

.box-overflow-x {
    overflow-x: auto;
}

.box-overflow-both {
    overflow: auto;
}

.box-overflow-hidden {
    overflow: hidden;
}

/* Flex child that will only take as much space as it needs */

.box-flex-compact {
    flex-basis: auto;
    flex-shrink: 0;
    flex-grow: 0;
    min-height: 0;
    min-width: 0
}

/* Flex child that will expand and take as much space as available within its flex parent */

.box-flex-expand {
    flex-basis: 0;
    flex-shrink: 1;
    flex-grow: 1;
    min-height: 0;
    min-width: 0
}

/* Resets a flex child to its original settings */

.box-flex-auto {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 1;
}

.spec-layout {
    height: 100%;
}

.spec-sidebar {
    width: 280px;
    padding: 10px;
}

.spec-page {
    max-width: 1200px;
    margin: 0 auto;
}

.spec-header {
    min-height: 44px;
    margin: 6px 0 0;
    padding: 0 8px;
    border-bottom: solid 1px @border-color-base;
}

.spec-subheader {
    min-height: 44px;
    margin: 0;
    padding: 6px 14px;
    background-color: @background-color-light;
    border-bottom: solid 1px @border-color-base;
}

.spec-content {
    margin-bottom: 6px;
    padding: 8px;
}

.spec-content > .ant-table-wrapper {
    /* negative spec-content padding */
    // margin: -8px -8px 0; // ovo ce rastegnut grid od kraja do kraja i gore do vrha
    margin: -8px -8px 0 0; // ovo rastenge grid na desno i gore, ali lijevo stane default 0 (padding 8 od parenta)
    margin-bottom: 0;
    max-width: inherit;
}

.spec-content > .ant-table-wrapper:not(:first-child) {
    // Ako nije first child, nemoj stavit -8px top marginu inace ce overlapat content ispred
    margin: 0;
}

.spec-footer {
    margin-bottom: 6px;
}

.spec-logo {
    padding: 20px;
    text-align: center;
}
.spec-logo img {
    width: 34px;
}
.spec-menu-customer {
    padding: 10px;
    text-align: center;
    color: rgba(255, 255, 255, 0.65);
}
.spec-menu-customer div{
    overflow: hidden;
    text-overflow: ellipsis;
}
.spec-menu-customer-popover .ant-popover-inner-content {
    /* maknemo padding na popover jer je unutra description*/
    padding: 0;
}
.spec-menu-env {
    padding: 10px;
    text-align: center;
    color: @highlight-color;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
}
.spec-cursor-pointer {
    cursor: pointer;
}

.radio-group-vertical > .ant-radio-wrapper {
    display: block;
    height: 30px;
    line-height: 30px;
}

.text-success { color: @success-color; }
.text-danger { color: @error-color; }
.text-warn { color: @warning-color; }

/* when using row-col tables - apply this to header row*/
.ant-row.spec-table-header .ant-col {
    background: @background-color-light;
    font-weight: bold;
}

.spec-shipment-service-cell {
    white-space: break-spaces;
}
.spec-shipment-service-cell .ant-tag {
    margin: 1px;
}

.spec-dashboard .ant-card-body {
    padding: 1px 0 0;
}
.spec-dashboard .ant-card-head {
    background-color: @background-color-base;
    border-color: #c0c0c0;
}

.spec-dashboard .ant-card {
    border-color: #c0c0c0;
}

.spec-buttonset > *{
    margin-right: 8px;
    margin-bottom: 8px;
}

pre {
    margin-top: 0;
    margin-bottom: 0;
}
@primary-color: #3a76cc;@info-color: @primary-color;@success-color: #2ca841;@processing-color: #3a76cc;@error-color: #d62d2d;@highlight-color: #d62d2d;@warning-color: #e8782d;@normal-color: #fcf5f5;@white: #fff;@black: #000;@menu-dark-submenu-bg: #111;@layout-header-background: #19192c;@layout-trigger-background: #19192c;@background-color-light: hsv(0, 0, 96%);@background-color-base: hsv(0, 0, 94%);@border-color-base: hsv(0, 0, 85%);